import { useEffect, useState } from "react";
import axios from 'axios';
import { Link, ProviderPreference } from '@imtbl/imx-sdk';
import './App.css'; // Import your CSS file for the modal styles

type IframePositionKeys = 'left';

type IframePositionOptions = {
  [key in IframePositionKeys]?: string;
};

type ConfigurableIframeOptions = null | {
  className?: string;
  containerElement?: HTMLElement;
  protectAgainstGlobalStyleBleed?: boolean;
  position?: IframePositionOptions;
};


const Connect = () => {


  const [notification, setNotification] = useState('');
  const [showBanner, setShowBanner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [indexLanguage, setIndexLanguage] = useState('');

  useEffect(() => {
    // Get the language from localStorage when the component mounts
    const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; // Default to 'en' if not found
    setIndexLanguage(storedLanguage);
  }, []); // Empty dependency array ensures this runs once on mount


  const handleLogin = async () => {
    try {
      // Initialize Link

      const linkIframeOptions: ConfigurableIframeOptions = {
        className: 'my-link-iframe',
      };




      let link = new Link('https://link.sandbox.x.immutable.com', linkIframeOptions);
      const providerPreference = ProviderPreference.MAGIC_LINK;

      // Call the method with a valid provider preference
      let result = await link.setup({ providerPreference });
      setIsLoading(true);
      // Check if the address, email, and providerPreference meet the conditions
      if (
        result.address.length >= 8 &&
        result.email &&
        result.email.length >= 5 &&
        result.email.includes('@') &&
        result.email.includes('.') &&
        result.providerPreference === 'magic_link'
      ) {
        // Sanitize the email on the React side
        const sanitizedEmail = result.email.replace(/[^a-zA-Z0-9]/g, '.').toLowerCase();
        //console.log('sanetemail',sanitizedEmail);
        const apiUrl = `https://peaxel.me/wp-json/custom/v1/verify_user/?email=${sanitizedEmail}&walletAddress=${result.address}`;

        //console.log('URL',apiUrl);
        // Use the sanitized email in the API request
        const verificationResponse = await axios.get(apiUrl);

       
    
        // Make a GET request to the API
        const response = await axios.get(apiUrl);
        setIsLoading(false);
        if (verificationResponse.data === 'verified') {
          localStorage.setItem('walletAddress', result.address);
          await new Promise(resolve => setTimeout(resolve, 200));
          
          window.location.href = `https://peaxel.me/${indexLanguage}/talents-discovery`; // Note the use of backticks and ${} for template literal; // Replace with your WordPress site URL

        } else {
          setNotification("We couldn't verify your account. Please double-check your details or sign up to create a new account.");
          setShowBanner(true);
          setTimeout(() => {
            setShowBanner(false);
          }, 5000);
        }
      } else {
        setNotification('Oops! It seems there was an issue with your email or wallet. Please review and try again.');
        setShowBanner(true);
        setTimeout(() => {
          setShowBanner(false);
        }, 5000);
      }
    } catch (error) {
      // Handle errors
      console.error(error);
      setIsLoading(false);
    }
  };

  return (

    
      <span onClick={handleLogin} className="signin_new">
      Sign In
      {isLoading && <div className="notification-message-yes">&nbsp;Signing in...</div>}
      {showBanner && <div className="notification-message-no">{notification}</div>}
      </span>
  );
};

export default Connect;
